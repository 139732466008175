<template>
    <div class="gallery">
      <v-img 
        :src="require('../assets/images/hebrewsbanner.png')"
        max-width="312px"        
        contain
        >
      </v-img>        
        <v-row>
            <v-col
            v-for="(item, index) in beerList"
            :key="index"
            class="d-flex child-flex"
            cols="3"
            >
            <v-hover v-slot="{ hover }">
                <v-img
                    :src="require('../assets/images/small/' + item.image)"
                    :lazy-src="require('../assets/images/labelboilerplate.jpg')"
                    aspect-ratio="1"
                    class="grey lighten-2 gallery-item"
                    position="right center"
                    lazy
                >
                <v-fade-transition>
                    <div
                        v-if="hover"
                        class="gallery-overlay"
                    >
                        <div>
                            <p class="overlay-name"><strong> {{ item.name }}</strong></p>
                            <p class="overlay-text"> {{ item.style }}</p>
                            <p class="overlay-text"> {{ item.abv }} ABV</p>
                            <p class="overlay-text"> {{ item.ibu }} IBU</p>
                        </div>
                    </div>             
                </v-fade-transition>   
                    <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                        ></v-progress-circular>
                    </v-row>
                    </template>
                </v-img>
            </v-hover>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default ({    
    props: {        
        beerList: Array
    },
    computed: {
        beers() {
            console.log(this.beerList.beers)
            return this.beerList.beers    
        } 
    }
})
</script>


<style scoped>
    .gallery {
        padding: 24px;
        padding-top: 12px;
    }
    /* .gallery-item:hover {
            transition: 0.1s;
            opacity: 0.3;
    } */
    .gallery-overlay {
        transition: 0.1s;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        text-align: center;
        padding-top: 10%;
    }
    .overlay-name {
        font-size: 2vw;
        margin-bottom: 2%;
    }
    .overlay-text {
        font-size: 1.5vw;
        margin-bottom: 2%;        
    }
</style>