<template>
  <v-app>
    <v-app-bar
      color="deep-purple accent-4"
      dark      
      app      
      :extended = filters

    >
      <v-app-bar-nav-icon 
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>


      <v-toolbar-title class="text-caption text-lg-h4 pr-6">He Brews - Home Brewing in Essex since 2016</v-toolbar-title>
      <v-spacer></v-spacer>

        <v-text-field
          v-model="searchText"
          @focus="searchClosed = false"
          @blur="searchClosed = true"
          :background-color= "searchClosed ? 'rgba(0, 0, 0, 0)' : ''"
          placeholder="Search"
          prepend-inner-icon="mdi-magnify"
          class="mt-6"        
          :class="{ 'closed' : searchClosed && !searchText}"
          rounded
          filled
          dense
          x-large
        ></v-text-field>

      <v-btn icon
        @click="filters = !filters; searchAndFilter()"
      >
        <v-icon>mdi-filter</v-icon>
      </v-btn>
      <template v-slot:extension v-if="filters">
        <v-row class="my-0"
          :style="{height: 'inherit'}"
        
        >
          <v-col
            class="py-0"
            :style="{maxWidth: '28%', height: 'inherit'}"
          >
            <v-select
              v-model="selectedStyles"
              class="mt-4 my-0 px-0 text-caption"
              :items="beerStyles"
              :menu-props="{ maxHeight: '400' }"
              multiple              
              dense             
              placeholder="Styles"
            ></v-select>   
          </v-col>
          <v-col
          class="slider-col pb-0 px-0 "
          :style="{height: 'inherit'}"

          > 
            <v-range-slider
              v-model="abvRange"
              dense
              max=15
              min=0
              step=0.1
              class="mt-0 py-0 text-caption"
            >
              <template v-slot:prepend>
                <div class="mt-1">
                  {{ abvRange[0] + '%' }}
                </div>
              </template>
              <template v-slot:append>
                <div class="mt-1">
                  {{ abvRange[1] + '%' }}
                </div>                
              </template>
            </v-range-slider>      
          </v-col>
          <v-col
            class="py-0"
            :style="{maxWidth: '28%', height: 'inherit'}"
          >
            <v-select
              v-model="selectedSort"
              class="mt-4 my-0 px-0 text-caption"
              :items="['New-Old','Old-New','A-Z','Z-A']"
              :menu-props="{ maxHeight: '400' }"
              dense
            ></v-select>   
          </v-col>          
        </v-row>    
      </template>      

    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      bottom
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
   <Gallery :beerList=beerData />
    </v-main>
  </v-app>
</template>

<script>
import Gallery from './components/Gallery';
import beerData from "/public/beers.json";    


export default {
  name: 'App',

  components: {
    Gallery
  },

    data: () => ({
      drawer: false,
      filters: false,
      group: null,
      searchClosed: true,
      searchText: null,
      selectedStyles: [],
      selectedSort: "New-Old",
      beerStyles: ["IPA", "Pale", "Porter", "Stout", "Saison", "Sour", "Mild"],
      abvRange: [0, 15],
      beerData: beerData.beers
      // beerData: beerData
    }),
    mounted: function () {
      console.log(this)
      this.sort(this.selectedSort)
    },
    computed: {
      // beerData() {
      //   this.searchAndFilter()      
      //   var initialList = beerData.beers
      //   return initialList.filter((element) => { 
      //     if(this.searchText){
      //       return element.name.toLowerCase().includes(this.searchText.toLowerCase()) 
      //     }else{
      //       return true
      //     }
      //   })
      // },

    },
    methods: {
      searchAndFilter(){
        var initialList = beerData.beers
        //Search bar
        var filteredList = initialList.filter((element) => { 
          if(this.searchText){
            return element.name.toLowerCase().includes(this.searchText.toLowerCase()) 
          }else{
            return true
          }
        })
        //Style filter
        filteredList = filteredList.filter((element) => { 
          if(this.selectedStyles.length > 0){
            return this.selectedStyles.some((style) => { 
              return element.style.toLowerCase().includes(style.toLowerCase()) })
          }else {
            return true
          }
        })
        //ABV filter
        if(this.filters){
          filteredList = filteredList.filter((element) => { 
            var thisABV = parseFloat(element.abv.replace("%", ""))
            return thisABV >= this.abvRange[0] && thisABV <= this.abvRange[1]
          })        
        }
        this.beerData = filteredList;
      },
      sort(type){
        console.log("sorting by " + type)
        var sortFunction;

        switch (type) {
          case "New-Old":
            sortFunction = (a, b)=>{
              const datesplitA = a.released.split("/")
              const dateA = new Date(datesplitA[2], datesplitA[1]-1, datesplitA[0])
              const datesplitB = b.released.split("/")
              const dateB = new Date(datesplitB[2], datesplitB[1]-1, datesplitB[0])
              if(dateA > dateB){
                return -1
              } else{
                return 1
              }
            }          
            break;

          case "Old-New":
            sortFunction = (a, b)=>{
              const datesplitA = a.released.split("/")
              const dateA = new Date(datesplitA[2], datesplitA[1]-1, datesplitA[0])
              const datesplitB = b.released.split("/")
              const dateB = new Date(datesplitB[2], datesplitB[1]-1, datesplitB[0])
              if(dateB > dateA){
                return -1
              } else{
                return 1
              }
            }     
            break;          
          case "A-Z":
            sortFunction = (a, b)=>{
              return ('' + a.name).localeCompare(b.name);
            }
            break;
          case "Z-A":
            sortFunction = (a, b)=>{
              return ('' + b.name).localeCompare(a.name);
            }
          break;
        }

        this.beerData = this.beerData.sort(sortFunction)

      }
    },

    watch: {
      group () {
        this.drawer = false
      },
      selectedStyles(){
        this.searchAndFilter()      
      },      
      selectedSort(){
        this.sort(this.selectedSort)
      },
      searchText () {
        this.searchAndFilter()
      },
      abvRange () {
        this.searchAndFilter()
      }
    },
  }
</script>

<style>
  .v-input.closed{
    max-width: 12px !important;
    transition: 0.3s;

  }
  .v-input__slot{
    background-color: rgba(0,0,0,0) !important;
    cursor: pointer !important;
    padding: 0px !important;
  }
  .slider-value, .slider-value-left{
    /* width: 64px; */
    /* margin-top: 0px; */
    /* align-items: center; */

    color: white;
  }
  .slider-value-left{
    text-align-last: end;
  }
  .slider-col{
    align-self: center;
  }
</style>